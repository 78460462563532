import 'utilities/interFontFace.js';
import { assign } from 'utilities/assign.js';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { loadEv1 } from 'utilities/loadEv1.js';
import { cast, clone, merge } from 'utilities/obj.js';
import { seqId } from 'utilities/seqid.js';
import { bindify } from 'utilities/bindify.js';
import { injectJsonLd, removeInjectedJsonLd } from 'utilities/injectJsonLd.js';
import { atOrNearEnd, setLastTime } from 'utilities/resumableVideoData.js';
import { clearTimeouts } from 'utilities/timeout-utils.js';
import { countMetric, sampleMetric } from 'utilities/simpleMetrics.js';
import { h, render } from 'preact';
import { elemBind, elemHeight, elemInDom, elemOffset, elemWidth } from 'utilities/elem.js';
import { Url } from 'utilities/url.js';
import { batchFetchData, formatAndCacheData } from 'utilities/batchFetchMediaData.js';
import { eV1HostWithPort } from 'utilities/hosts.js';
import { afterPageLoaded, pageLoadedAndIdle } from 'utilities/page-loaded.js';
import { getShortDescription } from 'utilities/getShortDescription.js';
import { embedOptionsFromQueryParams } from 'utilities/embedOptionsFromQueryParams.js';
import './SubscribeControl.js';
import { isMouseDownRecently } from 'utilities/isMouseDown.js';
import { unescapeHtml } from 'utilities/core.js';
import { globalEventLoop } from 'utilities/event_loop.js';
import { still } from 'utilities/assets.js';
import { interFontFamily } from 'utilities/interFontFamily.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { reportError } from 'utilities/sentryUtils.ts';
import { isNil, isNotNil } from '@wistia/type-guards';
import { fetchMediaData } from 'utilities/fetchMediaData.ts';
import { Wistia } from '../../wistia_namespace.ts';
import { appHostname } from '../../appHostname.js';
import galleryRegistry from './init/galleryRegistry.js';
import { getChannelStorage, updateChannelStorage } from './channelStorage.js';
import { getMetaContent, upsertMetaTag } from './meta-tag-manip.js';
import {
  fetchGalleryDataFromSpeedDemonScript,
  fetchGallerySeedDataFromProject,
} from './fetchGalleryData.js';
import GalleryHistory from './GalleryHistory.js';
import GalleryOverlay from './GalleryOverlay.jsx';
import GalleryPopoverCard from './GalleryPopoverCard.jsx';
import GalleryView from './GalleryView.jsx';
import GalleryViewErrorBoundary from './GalleryViewErrorBoundary.jsx';
import { DEFAULT_FONT_NAME } from './defaultFont.js';
import {
  getRouteDataFromUri,
  originalCanonicalUrl,
  setRouteDataOnUri,
} from './uriStateTransformers.js';
import getBodyClass from './getBodyClass.js';
import { cachedDetect as detect } from '../../utilities/detect.js';
import { getCurrentFontsInDocument, loadCustomFont } from '../../utilities/fonts.ts';
import { wlog } from '../../utilities/wlog.js';
import { HardWallWithPasswordForm } from '../shared/components/HardWall/HardWallWithPasswordForm.tsx';
import { InvalidPasswordError } from '../shared/components/HardWall/InvalidPasswordError.ts';
import { FetchChannelDataTimeoutError } from './FetchChannelDataTimeoutError.ts';
import { HardWallWithLoginPrompt } from '../shared/components/HardWall/HardWallWithLoginPrompt.tsx';
import { HardWall } from '../shared/components/HardWall/HardWall.tsx';
import { speedDemonScriptExists } from '../../utilities/speedDemon.ts';

const DEFAULT_VIDEO_EMBED_OPTIONS = {
  plugin: {
    watchNext: {
      on: true,
    },
  },
  seo: false, // channel embed injects the json-ld, so the video doesn't need to
  silentAutoPlay: false,
  transition: 'fade',
  transitionTime: '100',
  wmode: 'transparent',
};

const DEFAULT_CHANNEL_EMBED_OPTIONS = {
  color: '1e71e7',
  googleFontDisplay: 'swap',
  googleFont: DEFAULT_FONT_NAME,
  videoCardsLayout: 'carousel',
  pageFixedHeaderHeight: 0,
  routeStrategy: 'query-params',
  history: window.history,
  headerFontSizeMultiplier: 1,
  foregroundColor: 'ffffff',
  backgroundColor: '000000',
  contentOffsetTop: 0,
  logoSizeMultiplier: 1,
};

const SMALLEST_MYSQL_INTEGER_VALUE = -0x80000000;

const DARK_EMBED_OPTIONS = {
  backgroundColor: '000000',
  foregroundColor: 'ffffff',
};

const waitForImgSrcToLoad = (src, tries = 0, maxTries = 15, interval = 2000) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;

    img.addEventListener(
      'load',
      () => {
        resolve(img);
      },
      false,
    );

    img.addEventListener(
      'error',
      () => {
        if (tries >= 15) {
          reject(new Error(`img src ${src} failed to load.`));
          return;
        }

        setTimeout(() => {
          resolve(waitForImgSrcToLoad(src, tries + 1, maxTries, interval));
        }, 2000);
      },
      false,
    );
  });
};

const addRevokeToQueue = (queueName, object) => {
  window[queueName] = window[queueName] || [];
  window[queueName].push({ revoke: object });
};

const maybeResetOriginalUrlForFreshUrl = () => {
  if (window.FreshUrl) {
    window.FreshUrl.originalUrl = window.location.href;
  }
};

if (!Wistia.galleryHistory) {
  Wistia.galleryHistory = new GalleryHistory();
}

if (!Wistia.Gallery) {
  Wistia._galleries = {};
  class Gallery {
    constructor(initPayload, options = {}) {
      try {
        this._hashedId = Object(initPayload).hashedId ? initPayload.hashedId : initPayload;
        if (options.beforeConstruct) {
          // For stubbing
          options.beforeConstruct(this);
        }

        this._view = {};

        this._hasDataPromise = new Promise((resolve) => {
          this.on('hasdata', resolve);
        });

        this._embeddedPromise = new Promise((resolve) => {
          this.on('embedded', resolve);
        });

        this._optionsFromConstructor = options;
        this._otherOptionSources = [];

        this._uuid = seqId('w-gallery-');

        if (typeof options.container === 'string') {
          this.container = document.getElementById(options.container);
        } else if (options.container instanceof HTMLElement) {
          this.container = options.container;
        } else {
          // TODO: more default behavior
          throw new Error('No container given.');
        }

        this.container.wistiaGallery = this;
        galleryRegistry.register(this._uuid, this.container, this);

        // when initializing, we want to check the route strategy to ensure we request
        // mediaData for any specific media if necessary
        this.seedDataOptions = {
          accountKey: this._options.accountKey,
          deliveryCdn: this._options.deliveryCdn,
          embedHost: this._options.embedHost,
          locked: this._options.locked,
          unauthorized: this._options.unauthorized,
        };
        const routeStrategyOptions = this.getRouteData();
        if (routeStrategyOptions.wmediaid) {
          this.seedDataOptions.wmediaid = routeStrategyOptions.wmediaid;
        }

        let channelFetchDataPromise;

        if (this._dataFromGalleryQueue) {
          channelFetchDataPromise = Promise.resolve(this._dataFromGalleryQueue);
        } else if (typeof initPayload === 'string') {
          if (speedDemonScriptExists(initPayload, 'channel')) {
            channelFetchDataPromise = fetchGalleryDataFromSpeedDemonScript(
              initPayload,
              this.seedDataOptions,
            ).catch((e) => {
              // If speed demon fails for some reason, galleryData will be
              // undefined. In that case, fall back to XHR.
              console.error(e.message);
              console.error(e.stack);
              return fetchGallerySeedDataFromProject(initPayload, this.seedDataOptions).catch(
                (reason) => {
                  if (reason instanceof FetchChannelDataTimeoutError) {
                    return reportError('channel', new Error('Timed out fetching channel data'), {
                      channelHashedId: this.hashedId(),
                    });
                  }
                  if (reason instanceof Error) {
                    return reportError('channel', reason, {
                      channelHashedId: this.hashedId(),
                    });
                  }
                  return reportError('channel', new Error('Failed to channel data'), {
                    channelHashedId: this.hashedId(),
                  });
                },
              );
            });
          } else {
            channelFetchDataPromise = fetchGallerySeedDataFromProject(
              initPayload,
              this.seedDataOptions,
            ).catch((reason) => {
              if (reason instanceof FetchChannelDataTimeoutError) {
                return reportError('channel', new Error('Timed out fetching channel data'), {
                  channelHashedId: this.hashedId(),
                });
              }
              if (reason instanceof Error) {
                return reportError('channel', reason, {
                  channelHashedId: this.hashedId(),
                });
              }
              return reportError('channel', new Error('Failed to channel data'), {
                channelHashedId: this.hashedId(),
              });
            });
          }
        } else {
          // This isn't actually a hashedId; it's an object payload. Used in
          // iframes.
          channelFetchDataPromise = Promise.resolve(initPayload);
        }

        channelFetchDataPromise
          .then((galleryData) => {
            if (isNotNil(galleryData)) {
              this.trigger('hasdata');
              this.initWithData(galleryData, this._options);
            }
          })
          .catch((e) => {
            if (e.locked) {
              window.location.href = `https://${appHostname(
                this._options.accountKey,
              )}/channels/${this.hashedId()}/login_redirect${window.location.search}`;
              return;
            }
            this.countMetric('error'); // Increment the total number of channel errors
            this.countMetric('data-fetch-failure'); // Increment the number of data fetch failures
            reportError('channel', e); // Report the error to Sentry
            throw e; // Re-throw the original error
          });

        this._posterStatus = {};

        this._hasEmbedded = false;

        // some state to determine if the user has requested to watch the trailer via the "Play Trailer" button, which is a popover in and of itself
        this._isWatchingTrailer = false;
      } catch (error) {
        reportError('channel', error);
      }
    }

    initWithData(galleryData, options = {}) {
      this._galleryData = galleryData;
      // save the requestedVideoMediaData in the batchFetch cache if we already have the data
      if (this._galleryData.requestedVideoMediaData) {
        const { wmediaid } = this.getRouteData();
        formatAndCacheData(wmediaid, options, this._galleryData.requestedVideoMediaData);
      }
      const optionsFromServer = galleryData.embedOptions || {};
      if (galleryData.numericId) {
        optionsFromServer.channelId = galleryData.numericId;
      }

      this._justInitialized = true;
      this._optionsFromServer = optionsFromServer;
      this._applyOptions();
      this._originalTitle = document.title;
      this._originalMetaDescription = getMetaContent('description');
      this._originalTwitterTitle = getMetaContent('twitter:title');
      this._originalTwitterDescription = getMetaContent('twitter:description');
      this._originalOgDescription = getMetaContent('og:description');
      this._originalOgImage = getMetaContent('og:image');
      this._originalOgImageHeight = getMetaContent('og:image:height');
      this._originalOgImageWidth = getMetaContent('og:image:width');
      this._originalOgUrl = getMetaContent('og:url');
      this._originalTwitterSite = getMetaContent('twitter:site');
      this._originalTwitterImage = getMetaContent('twitter:image');
      this._unbinds = [];
      this.routeStrategy = this._options.routeStrategy;
      this._needsPassword = galleryData.unauthorized ?? false;
      this._isLocked = galleryData.locked ?? false;
      this._noAccess = (galleryData.noAccess && galleryData.locked) ?? false;
      this._hasHardWall = this._needsPassword || this._isLocked || this._noAccess;
      // In VMA, we want to be able to preview a channel embed before a Channel is created
      // We'll signal this state by setting the hashed ID to "ghost".
      // When `this._isGhostChannel` is true, we will bail early on calls to fetch episode media data
      // since the "ghost channel" rendered in VMA is not interactive.
      this._isGhostChannel = galleryData.hashedId === 'ghost';

      this.on('wayafterload', () => {
        const facebookPixel = Object(this._options.plugin).facebookPixel;
        if (facebookPixel) {
          dynamicImport('assets/external/channel/initFacebookPixel.js').then((mod) => {
            return mod.facebookPixelAndSubscribeEvent(facebookPixel, this);
          });
        }

        const hasGA4Access = !!this._optionsFromServer.plugin?.googleAnalytics4?.on;
        const googleAnalytics4 = Object(this._options.plugin).googleAnalytics4;
        if (hasGA4Access && googleAnalytics4) {
          dynamicImport('assets/external/channel/initGoogleAnalytics.js').then((mod) => {
            return mod.googleAnalyticsAndSubscribeEvent(googleAnalytics4, this);
          });
        }
      });

      // Logging init for pw channel launch to more easily see some
      // of the password-protected channel usage. This can be removed
      // once launch is far enough out.
      if (this._needsPassword) {
        this.countMetric('init-password');
      }

      /**
       * When this._hasHardWall is true, the initial call for channel data does not include
       * any episode/media data, and access to this is gated by the hard wall.
       *
       * Since we don't have access to any of the episode data, we'll return early
       * after rendering the hard wall.
       */
      if (this._hasHardWall) {
        this.renderHardWall();
        return;
      }

      if (this._options.mode === 'inline') {
        this._galleryViewContainer = this.container;
        this.renderGalleryView();
      } else {
        this._galleryCardContainer = this.container;
        this.renderGalleryCard();
      }

      const routeData = this.getRouteData();
      const url = this.getUrlWithRouteData(routeData);

      Wistia.galleryHistory.register(this, {
        history: this._options.history,
        routeData,
        url,
      });

      // If we did not receive an unauthorized response but the passwordProtectedChannel plugin is turned on,
      // then we have just unlocked the channel by entering a valid password. We will update the view
      // to show the channel overlay instead of the hard wall.
      if (this._options.mode === 'popover' && this.isPasswordProtected()) {
        this.setViewFromUri({
          inPopState: false,
          shouldUnlockAfterValidPasswordSubmission: true,
        });
      } else {
        this.setViewFromUri({ inPopState: false });
      }

      this.monitor();

      this.countMetric('init');
      // This is to determine if the viewer left off at the very end of the
      // channel, in which case we don't want to show the resume button.
      // Also, to determine if the most recently played media (even if not the
      // last media in the channel) was mostly played, in which case Resume
      // should play the _next_ media.
      Promise.all([
        this.fetchDurationOfLastMediaInChannel(),
        this.fetchDurationOfMostRecentlyPlayedMedia(),
      ]).then(() => {
        if (this._durationOfLastMediaInChannel == null || !this._galleryViewContainer) {
          return;
        }
        this.renderGalleryView();
      });

      window._wq = window._wq || [];
      this._channelVideoConfigObject = {
        id: '_all',
        onFind: this.onVideoInit,
        onHasData: this.onVideoHasData,
        onReady: this.onVideoReady,
      };
      window._wq.push(this._channelVideoConfigObject);

      window._wpq = window._wpq || [];
      this._channelPopoverConfigObject = {
        id: '_all',
        onFind: (popover) => {
          if (popover._options.channel !== this.hashedId()) {
            return;
          }

          popover.on('beforeopen', () => this.onOpenPopoverVideo(popover));
          popover.on('afteropen', () => this.onAfterOpenPopoverVideo(popover));
          popover.on('beforeclose', () => this.onClosePopoverVideo());
          popover.on('afterclose', () => this.onAfterClosePopoverVideo());
        },
      };
      window._wpq.push(this._channelPopoverConfigObject);

      window.wistiaPosterApiQueue = window.wistiaPosterApiQueue || [];
      this._channelPosterConfigObject = {
        id: '_all',
        onFind: (poster) => {
          if (poster._options.channel !== this.hashedId()) {
            return;
          }

          const unbindOnEnteredViewport = poster.on('enterviewport', () => {
            unbindOnEnteredViewport();
            this.onLoadingPoster(poster.hashedId());
            poster.embedded().then(() => {
              this.onLoadedPoster(poster.hashedId());
            });
          });
        },
      };
      window.wistiaPosterApiQueue.push(this._channelPosterConfigObject);
    }

    countMetric(key, more = {}) {
      try {
        countMetric(`channels/${key}`, 1, assign(this.metricPayload(), more));
      } catch (e) {
        // An exception in counting a metric should never cause anything else
        // to fail.
        setTimeout(() => {
          throw e;
        }, 0);
      }
    }

    sampleMetric(key, value, extraData = {}) {
      try {
        sampleMetric(`channels/${key}`, value, assign(this.metricPayload(), extraData));
      } catch (e) {
        // An exception in sending a metric should never cause anything else
        // to fail.
        setTimeout(() => {
          throw e;
        }, 0);
      }
    }

    metricPayload() {
      return {
        channelHashedId: this.hashedId(),
        location: location.origin + location.pathname,
      };
    }

    get _options() {
      if (this._cachedOptions) {
        return this._cachedOptions;
      }

      this._cachedOptions = merge(
        {},
        DEFAULT_CHANNEL_EMBED_OPTIONS,
        this._optionsFromServer,
        ...this._otherOptionSources,
        this._optionsFromConstructor,
        embedOptionsFromQueryParams(['deliveryCdn']),
        this._overrideOptions,
      );

      cast(this._cachedOptions);

      return this._cachedOptions;
    }

    _applyOptions(options) {
      const prevEmbedOptions = clone(this._options);
      this._cachedRouteStrategyOptions = undefined;
      this._cachedOptions = undefined;
      this._cachedVideoEmbedOptions = undefined;
      this._otherOptionSources.push(options);
      this.onEmbedOptionsUpdated(prevEmbedOptions);
    }

    _applyChannelData(data) {
      this._dataFromGalleryQueue = data;
    }

    sectionsThatHaveVideos() {
      return this._galleryData.series[0].sections.filter((section) => section.videos.length > 0);
    }

    getGalleryContext() {
      // gather all the inputs needed for galleryMath
      const sectionsThatHaveVideos = this.sectionsThatHaveVideos();
      const popoverScrollOffset =
        this._options.mode === 'popover' ? this._galleryViewContainer.getBoundingClientRect().y : 0;
      const newGalleryContext = {
        firstSectionHasName: sectionsThatHaveVideos.length > 0 && sectionsThatHaveVideos[0].name,
        galleryViewWidth: this._galleryViewContainer.clientWidth,
        offsetTop: elemOffset(this._galleryViewContainer).top - popoverScrollOffset,
        pageFixedHeaderHeight: this._options.pageFixedHeaderHeight,
        videoAspect: this._currentVideoAspect,
        videoCardsLayout: this._options.videoCardsLayout,
        windowHeight: elemHeight(window),
      };

      // Try to preserve the same object identify if there are no changes so
      // that we don't need to do any extra work in shouldComponentUpdate.
      const changed = anyValuesChanged(this._galleryContext, newGalleryContext, {
        cacheKeys: 'galleryContext',
      });
      if (this._galleryContext && !changed) {
        return this._galleryContext;
      }
      this._galleryContext = newGalleryContext;
      return newGalleryContext;
    }

    getBodyClass() {
      return getBodyClass(this._galleryViewProps);
    }

    getLeftContentOffset() {
      return this.getBodyClass().leftContentOffset(this._galleryViewProps);
    }

    onClosePopoverVideo = () => {
      this._isPopoverOpen = false;
      if (this._backgroundPoster) {
        this._backgroundPoster.whenControlMounted('video').then((video) => video.play());
      }
      if (this._closingVideoDueToNavigation) {
        this._closingVideoDueToNavigation = false;
        return;
      }

      this.updateView({ videoId: null });
      const routeData = {
        wchannelid: this.hashedId(),
      };

      removeInjectedJsonLd(this.jsonLdChannelId(this.hashedId()));

      if (this._closingVideoDueToNavigation) {
        this._closingVideoDueToNavigation = false;
        return;
      }

      const previousStateWasOnMedia =
        Wistia.galleryHistory.previousState?.wmediaid ||
        Wistia.galleryHistory.previousState?.wepisodeid;

      if (Wistia.galleryHistory.previousState && !previousStateWasOnMedia) {
        this._navigatingBackBecausePopoverClosed = true;
        Wistia.galleryHistory.back();
      } else {
        this.pushStateToHistory(routeData);
      }
      maybeResetOriginalUrlForFreshUrl();
    };

    onOpenPopoverVideo = (popover) => {
      this._isPopoverOpen = true;
      if (this._backgroundPoster) {
        this._backgroundPoster.whenControlMounted('video').then((video) => video.pause());
      }
      if (this._featuredMedia) {
        this._featuredMedia.pause();
      }
      if (this._openingVideoDueToNavigation) {
        this._openingVideoDueToNavigation = false;
        return;
      }
      const hashedId = popover.hashedId();
      const routeData = {
        wchannelid: this.hashedId(),
        wmediaid: hashedId,
      };
      this.pushStateToHistory(routeData);
      maybeResetOriginalUrlForFreshUrl();
      this.updateView({ videoId: hashedId });
    };

    jsonLdChannelId(channelId) {
      return `w-channel-${channelId}-json-ld`;
    }

    updateInjectedJsonLd(wmediaid, channelId) {
      if (this.isPasswordProtected() || this._isGhostChannel) {
        return;
      }

      if (wmediaid) {
        this.findMediaData(wmediaid).then((mediaData) => {
          const elemId = this.jsonLdChannelId(channelId);

          injectJsonLd(elemId, mediaData, {
            embedOptions: Wistia.api?.(wmediaid)?.embedOptions(),
            videoWidth: Wistia.api?.(wmediaid)?.videoWidth(),
            videoHeight: Wistia.api?.(wmediaid)?.videoHeight(),
          });
        });
      }
    }

    onAfterOpenPopoverVideo = () => {
      this.disablePosterVideos();
    };

    onAfterClosePopoverVideo = () => {
      this.enablePosterVideos();
    };

    channelPosters() {
      if (!Wistia.poster) {
        return [];
      }
      return Wistia.poster.all().filter((poster) => {
        return poster.embedOptions().channel === this.hashedId();
      });
    }

    channelPopovers() {
      if (!Wistia.popover) {
        return [];
      }
      return Wistia.popover.all().filter((popover) => {
        return popover.embedOptions().channel === this.hashedId();
      });
    }

    enableOpeningPopovers() {
      this.channelPopovers().forEach((popover) => {
        popover.setOpeningIsDisabled(false);
      });
    }

    disableOpeningPopovers() {
      this.channelPopovers().forEach((popover) => {
        popover.setOpeningIsDisabled(true);
      });
    }

    enablePosterVideos() {
      this.channelPosters().forEach((poster) => {
        poster.updateEmbedOptions({
          videoFeature: poster.embedOptions().videoFeatureWas,
          videoFeatureWas: undefined,
        });
      });
    }

    disablePosterVideos() {
      this.channelPosters().forEach((poster) => {
        poster.updateEmbedOptions({
          videoFeature: false,
          videoFeatureWas: poster.embedOptions().videoFeature,
        });
      });
    }

    fetchDurationOfLastMediaInChannel() {
      if (!this.getIdOfLastMediaInChannel() || this._isGhostChannel) {
        return Promise.resolve();
      }

      return batchFetchData(this.getIdOfLastMediaInChannel(), this._options, {
        basic: true,
      }).then(({ basic: { duration } }) => {
        this._durationOfLastMediaInChannel = duration;
      });
    }

    fetchDurationOfMostRecentlyPlayedMedia() {
      const mostRecentlyPlayedMediaId = this.mostRecentlyPlayedMediaId();
      if (!mostRecentlyPlayedMediaId || this._isGhostChannel) {
        return Promise.resolve();
      }

      return batchFetchData(mostRecentlyPlayedMediaId, this._options, {
        basic: true,
      }).then(({ basic: { duration } }) => {
        this._durationOfMostRecentlyPlayedMedia = duration;
      });
    }

    mostRecentlyPlayedMediaId() {
      return this.getStorage().lastVideoPlayed;
    }

    onColorChange() {
      if (this.currentVideo()) {
        this.currentVideo().playerColor(this._options.color);
        this.currentVideo().requestControls('channelPreview', 4000);
      }
      this._cachedVideoEmbedOptions = undefined;
      this.trigger('colorchange', this._options.color);
    }

    onEmbedOptionsUpdated(prevEmbedOptions) {
      if (this._options.color !== prevEmbedOptions.color) {
        this.onColorChange();
      }

      if (this._options.googleFont !== prevEmbedOptions.googleFont) {
        this.onGoogleFontChange();
      }

      if (this._options.customFontUrl !== prevEmbedOptions.customFontUrl) {
        this.onCustomFontUrlChange(prevEmbedOptions.customFontUrl, prevEmbedOptions.customFont);
      }

      if (this._options.subscribe?.required !== prevEmbedOptions.subscribe?.required) {
        this.trigger('subscribechange', this._options.subscribe);
      }

      if (
        this._options.forceDisplayLockedHardWall !== prevEmbedOptions.forceDisplayLockedHardWall
      ) {
        this.onForceDisplayLockedHardWallChange();
      }

      if (
        this._options.forceDisplayPasswordHardWall !== prevEmbedOptions.forceDisplayPasswordHardWall
      ) {
        this.onForceDisplayPasswordHardWallChange();
      }
    }

    updateEmbedOptions(optionsToApply) {
      const prevEmbedOptions = clone(this._options);
      return this.embedded().then(() => {
        this._overrideOptions = merge({}, this._overrideOptions, optionsToApply);
        this._cachedOptions = null;
        this._cachedVideoEmbedOptions = null;
        this.onEmbedOptionsUpdated(prevEmbedOptions);
        return this.updateRenderedViews();
      });
    }

    shouldShowResume() {
      return (
        this._options.resumable !== false &&
        this.getStorage().lastVideoPlayed &&
        !this.maybeReachedEndOfChannel()
      );
    }

    isMostRecentlyPlayed(mediaHashedId) {
      return this.getStorage().lastVideoPlayed === mediaHashedId;
    }

    maybeReachedEndOfChannel() {
      if (!this.isMostRecentlyPlayed(this.getIdOfLastMediaInChannel())) {
        return;
      }
      return (
        this.lastMediaInChannelMaybeMostlyPlayed() ||
        atOrNearEnd(this.getIdOfLastMediaInChannel(), this._durationOfLastMediaInChannel)
      );
    }

    lastMediaInChannelMaybeMostlyPlayed() {
      // we need the duration in order to check if the media is mostly played.
      return this._durationOfLastMediaInChannel == null;
    }

    resumableMediaTitle() {
      return this._resumableMediaTitle;
    }

    getRouteData() {
      const routeData = getRouteDataFromUri(location.href, this.getRouteStrategyOptions());

      // For an added layer of security, password-protected channel use episode id's in the URL
      // instead of media id's. If an episode id is present in the routeData,
      // we should translate it into its corresponding media id instead so the channel can use it.
      if (routeData.wepisodeid && this.isPasswordProtected()) {
        routeData.wmediaid = this.getMediaIdFromEpisodeId(routeData.wepisodeid);
        delete routeData.wepisodeid;
      }

      return routeData;
    }

    getSavedConversionData() {
      return this.getStorage().conversionData;
    }

    getVideoEmbedOptions = () => {
      if (this._cachedVideoEmbedOptions) {
        return this._cachedVideoEmbedOptions;
      }

      const result = merge({}, DEFAULT_VIDEO_EMBED_OPTIONS, {
        channel: this.hashedId(),
        channelId: this._galleryData.numericId,
        channelPassword: this._galleryData.embedOptions?.plugin?.passwordProtectedChannel?.password,
        contentTypeLabel: this._options.contentTypeLabel,
        episodeIdMappings: this._galleryData.series
          .flatMap((series) => series.sections.flatMap((section) => section.videos))
          .reduce((acc, { hashedId, episodeNumericId }) => {
            return { ...acc, [hashedId]: episodeNumericId };
          }, {}),
        deliveryCdn: this._options.deliveryCdn,
        email: this._galleryData.userEmail,
        embedHost: this._options.embedHost,
        hideAudioContextMenu: true,
        navigation: this._options.navigation,
        playerColor: this._options.color,
        playlistLoop: this._options.playlistLoop,
        plugin: {
          watchNext: {
            on: true,
            playerColor: this._options.color,
          },
        },
        podcastingIsEnabled: this._options.podcasting,
        resumable: this._options.resumable !== false,
        shouldShowDescription: this._options.shouldShowVideoDescriptions,
        // allow for forwarding custom stats urls to each video
        statsUrl: this._options.statsUrl,

        // Note: This will storage key will apply even to turnstiles that have
        // been included "Conditionally".
        turnstileStorageKey: [`channel_${this.hashedId()}`, 'conversionData'],
        upNextDisplayDuration: this._options.upNextDisplayDuration,
        videoContainerId: this.videoContainerId(),
        channelPreferences: getChannelStorage(this.hashedId()),
      });

      const googleAnalytics4Options = Object(this._options.plugin).googleAnalytics4 || {};
      if (googleAnalytics4Options.on) {
        result.plugin.googleAnalytics4 = googleAnalytics4Options;
      }

      const emailCollectorOptions = Object(this._options.plugin).emailCollector || {};
      if (emailCollectorOptions.on && !this.getSavedConversionData()) {
        // It's assumed that emailCollectorOptions mirror Turnstile options
        // directly right now. In the event that this changes, this is the
        // place where we'd translate and transform the options to do what we
        // want.
        result.plugin['requireEmail-v1'] = emailCollectorOptions;
        result.plugin['requireEmail-v1'].alwaysShow = true;
        result.plugin['requireEmail-v1'].allowSkip = false;
        result.plugin['requireEmail-v1'].time = 'before';
      } else if (emailCollectorOptions.on === false) {
        result.plugin['requireEmail-v1'] = { on: false };
      } else if (this.getSavedConversionData()) {
        result.plugin['requireEmail-v1'] = { on: false };
      }
      // if none of the 👆 conditionals are met
      // turnstile options bubble up from individual video customizations

      if (this._options.subscribe && this._options.subscribeButtonOnVideo) {
        result.subscribeButton = true;
        result.onClickSubscribe = this.onClickVideoSubscribeButton;
      }

      this._cachedVideoEmbedOptions = result;

      return result;
    };

    onClickVideoSubscribeButton = () => {
      this.countMetric('clicked-open-subscribe-from-video');
      this._videoAtClose = this._view.videoId;
      if (Object(Wistia.PopoverV3)._activePopover) {
        this._timeAtClose = Wistia.PopoverV3._activePopover.video.time();
      }
      this.closeVideo();
      setTimeout(() => {
        this.setSubscribeFormIsVisible(true);
      }, 300);
    };

    hasData() {
      return this._hasDataPromise;
    }

    embedded() {
      return this._embeddedPromise;
    }

    embedOptions() {
      return this._options;
    }

    ready() {
      return this.embedded();
    }

    isPasswordProtected() {
      return (
        this._galleryData?.embedOptions?.plugin?.passwordProtectedChannel?.on === 'true' ||
        this._galleryData?.embedOptions?.plugin?.passwordProtectedChannel?.on === true
      );
    }

    getIsTweakModeEnabled = () => {
      return this._tweakMode;
    };

    setIsTweakModeEnabled(bool) {
      this._tweakMode = bool;
      this.updateRenderedViews();
    }

    setContentTypeLabel(label) {
      this.updateEmbedOptions({ contentTypeLabel: label });
    }

    setNavigation(navigation) {
      this.updateEmbedOptions({ navigation });
    }

    setHeaderFontSizeMultiplier(headerFontSizeMultiplier) {
      this.updateEmbedOptions({ headerFontSizeMultiplier });
    }

    setLogoSizeMultiplier(logoSizeMultiplier) {
      this.updateEmbedOptions({ logoSizeMultiplier });
    }

    getLogoEnabled() {
      return Boolean(this._options.logoEnabled && this._options.logoUrl);
    }

    setLogoEnabled(logoEnabled) {
      this.updateEmbedOptions({ logoEnabled });
    }

    getHeaderFontFamily() {
      const result = [`'${this._options.googleFont}'`, interFontFamily];

      if (this._options.customFont) {
        result.unshift(`'${this._options.customFont}'`);
      }

      return result.join(', ');
    }

    // Used by channel customize to conditionally display the locked hard wall
    onForceDisplayLockedHardWallChange() {
      if (this._options.forceDisplayLockedHardWall) {
        this.renderHardWall();
        return;
      }

      if (!this._hasHardWall) {
        this.renderGalleryView();
      }
    }

    // Used by channel customize to conditionally display the password hard wall
    onForceDisplayPasswordHardWallChange() {
      if (this._options.forceDisplayPasswordHardWall) {
        this.renderHardWall();
        return;
      }

      if (!this._hasHardWall) {
        this.renderGalleryView();
      }
    }

    onCustomFontUrlChange(previousCustomFontUrl, previousCustomFontFamily) {
      if (previousCustomFontUrl) {
        // unload the previous custom font using the font api
        document.fonts.delete(
          new FontFace(previousCustomFontFamily, `url(${previousCustomFontUrl})`),
        );
      }

      const currentFonts = getCurrentFontsInDocument();

      // we can return if there is no custom font url to set or the font is
      // already loaded in the document
      if (!this._options.customFontUrl || currentFonts.has(this._options.customFont)) {
        return;
      }

      loadCustomFont(this._options.customFont, this._options.customFontUrl).catch((e) => {
        wlog.error(
          `Channels: Error loading custom font ${this._options.customFont} with url ${this._options.customFontUrl}`,
          e,
        );
      });
    }

    onGoogleFontChange() {
      // channel embed codes now have a `link` tag to a css file for the google
      // font. However, if the newFontFamily is not the one already loaded on the
      // page, which will happen when switching between fonts in the editor, or
      // if the embed code is old and does not have the `link` tag in it, we
      // should load it.

      const fontProxyHref = `https://${
        this._options.embedHost || eV1HostWithPort()
      }/embed/channel/project/${this.hashedId()}/font.css`;
      const linkTagForFontProxy = document.querySelector(`link[href="${fontProxyHref}"]`);

      const encodedFontFamily = encodeURIComponent(`${this._options.googleFont}:400,700`).replace(
        '%20',
        '+',
      );
      const fontDisplay = this._options.googleFontDisplay;
      const specificFontHref = `https://fonts.googleapis.com/css?family=${encodedFontFamily}&display=${fontDisplay}`;
      const linkTagForSpecificFont = document.querySelector(`link[href="${specificFontHref}"]`);

      const googleFontOptionFromServer = this._galleryData.embedOptions.googleFont;

      const shouldAddLinkTag = () => {
        if (linkTagForSpecificFont) {
          return false;
        }

        return linkTagForFontProxy && this._options.googleFont !== googleFontOptionFromServer;
      };

      if (shouldAddLinkTag()) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = specificFontHref;
        document.head.appendChild(link);
      }
    }

    setGoogleFont(googleFont) {
      const optionsToSet = { googleFont };

      // When setting a Google Font we must clear out the custom font, so that
      // the custom font doesn't override it. We have to set it to an empty
      // string, rather than null/undefined, so it doesn't get deleted during
      // calls to `merge` (which would make it get reinstated if it's in optionsFromServer).
      if (googleFont) {
        optionsToSet.customFont = '';
        optionsToSet.customFontUrl = '';
      }

      this.updateEmbedOptions(optionsToSet);
    }

    setCustomFont(customFont) {
      const optionsToSet = { customFont };

      if (customFont) {
        optionsToSet.googleFont = DEFAULT_FONT_NAME;
      }

      this.updateEmbedOptions(optionsToSet);
    }

    setCustomFontUrl(customFontUrl) {
      this.updateEmbedOptions({ customFontUrl });
    }

    setShouldShowHeader(bool) {
      this.updateEmbedOptions({ header: { on: bool } });
    }

    setShouldShowFeatured(shouldShowFeatured) {
      this.updateEmbedOptions({ featured: { on: shouldShowFeatured } });
    }

    setShouldShowSearch(bool) {
      this.updateEmbedOptions({ shouldShowSearch: bool });
    }

    setShouldShowTranscript(bool) {
      this.updateEmbedOptions({ shouldShowTranscript: bool });
    }

    setShouldShowVideoDescriptions(shouldShowVideoDescriptions) {
      this.updateEmbedOptions({ shouldShowVideoDescriptions });
    }

    getSubscribeFormIsVisible() {
      return this.subscribeEnabled() && this._view.subscribeFormIsVisible;
    }

    setSubscribeFormIsVisible(shown) {
      this.updateView({
        videoId: null,
        sectionId: null,
        subscribeFormIsVisible: shown,
      });
      if (shown === false) {
        this.restoreClosedVideoAndTime();
      }
    }

    getMediaIdFromEpisodeId(episodeId) {
      const { episodeIdMappings } = this.getVideoEmbedOptions();
      return Object.keys(episodeIdMappings).find(
        (key) => episodeIdMappings[key].toString() === episodeId.toString(),
      );
    }

    setViewFromUri({ inPopState, shouldUnlockAfterValidPasswordSubmission }) {
      if (this._isGhostChannel) {
        return;
      }

      maybeResetOriginalUrlForFreshUrl();

      this._view = {};
      const { wchannelid, wmediaid } = this.getRouteData();

      // this channel should be open, and we should show a video in it
      if (wchannelid === this.hashedId() && wmediaid) {
        if (inPopState) {
          this._openingVideoDueToNavigation = true;
        }
        this.updateView({
          shouldShowOverlay: this._options.mode !== 'inline',
          videoId: wmediaid,
        });

        this.updateInjectedJsonLd(wmediaid, this.hashedId());
        // it's important for `playVideo` here to come after `updateView`, since
        // if we're in a popover channel and are initializing it, we haven't yet
        // rendered the GalleryView, and thus haven't initialized the popover-v3
        // embeds in the video cards. Those must initialize before we can get a
        // handle to and play them (as we do in `playVideo`).
        this._hashedIdOfMediaToPlayAfterSubscribe = wmediaid;
        if (this.getSubscribeIsRequired() && !this.viewerIsSubscribed()) {
          this.setSubscribeFormIsVisible(true);
          return;
        }
        this.openAndMaybePlayEpisode(wmediaid, {
          disableScaleAnimation: true,
          showFocusOutline: false,
        });

        this._navigatingBackBecausePopoverClosed = false;
        return;
      }

      // this channel should be open, but a video should not be open
      if (wchannelid === this.hashedId() && !wmediaid) {
        if (inPopState && this._currentVideoId && !this._navigatingBackBecausePopoverClosed) {
          this._closingVideoDueToNavigation = true;
        }
        this.closeVideo();
        this.updateView({
          shouldShowOverlay: this._options.mode !== 'inline',
          videoId: null,
        });

        this._navigatingBackBecausePopoverClosed = false;
        return;
      }

      // this channel should be open because we have just entered a valid password
      if (isNil(wchannelid) && shouldUnlockAfterValidPasswordSubmission) {
        this.openGalleryAndUpdateUrl();
        return;
      }

      // perhaps a channel should be open, but it ain't this one
      if (wchannelid !== this.hashedId()) {
        if (inPopState && this._currentVideoId && !this._navigatingBackBecausePopoverClosed) {
          this._closingVideoDueToNavigation = true;
        }

        if (Object(Wistia.PopoverV3)._activePopover) {
          this.closeVideo();
        } else if (!document.querySelector('link[rel=canonical]')) {
          // There's no canonical tag provided by the host page, but we really
          // should have one.
          this.setCanonicalTag();
        }

        this.setView({});
        this._navigatingBackBecausePopoverClosed = false;
      }
    }

    closeVideo() {
      if (!Wistia.PopoverV3 || !Wistia.PopoverV3._activePopover) {
        return;
      }
      const popover = Wistia.PopoverV3._activePopover;
      if (popover) {
        popover.close();
      }
    }

    openAndMaybePlayEpisode(hashedId, opts = {}) {
      return new Promise((resolve) => {
        dynamicImport('assets/external/E-v1.js');
        dynamicImport('assets/external/poster.js');
        dynamicImport('assets/external/popover-v3.js').then(() => {
          Wistia.popover.once(hashedId, (popover) => {
            if (opts.disableScaleAnimation) {
              popover._disableScaleAnimation(true);
            }

            if (opts.initiatingElem) {
              popover.initiatingElem(opts.initiatingElem);
            }

            if (opts.showFocusOutline != null && popover) {
              popover.setShouldShowFocusOutline(opts.showFocusOutline);
            }

            popover.open().then(() => {
              if (opts.shouldPlay) {
                popover.play();
              }
              resolve();
            });
          });
        });
      });
    }

    triggerBeforeSubscribe = () => {
      this.trigger('beforesubscribe');
    };

    exitGalleryAndUpdateUrl = () => {
      this.setView({});
      // If we're exiting the overlay because we closed the hard wall without submitting a valid password
      // we should not change the window history.
      if (this._hasHardWall) {
        return;
      }
      if (!Wistia.galleryHistory.previousState || !Wistia.galleryHistory.previousState.wchannelid) {
        Wistia.galleryHistory.back();
      } else {
        this.pushStateToHistory({});
      }
    };

    openGalleryAndUpdateUrl() {
      const routeData = {
        wchannelid: this.hashedId(),
      };

      this.updateView({
        shouldShowOverlay: true,
      });

      this.pushStateToHistory(routeData);
    }

    findMediaData(hashedId) {
      const trailerId = this._options.trailerId;
      if (trailerId && trailerId === hashedId) {
        return fetchMediaData(hashedId, this._options);
      }

      const requestedVideoMediaData = Object(this._galleryData.requestedVideoMediaData).mediaData;
      if (requestedVideoMediaData && requestedVideoMediaData.hashedId === hashedId) {
        return Promise.resolve(this._galleryData.requestedVideoMediaData.mediaData);
      }

      return fetchMediaData(hashedId, this._options);
    }

    renderGalleryCard() {
      if (!this._galleryData) {
        return;
      }

      const GalleryPopoverCardComponent = this._galleryPopoverCardComponent || GalleryPopoverCard;
      render(
        <GalleryPopoverCardComponent
          color={this._options.color}
          elemRef={(elem) => (this._cardEl = elem)}
          fadeInTime={2000}
          galleryEmbedOptions={this._options}
          headerFontFamily={this.getHeaderFontFamily()}
          headerFontSizeMultiplier={this._options.headerFontSizeMultiplier}
          heroImageAspectRatio={this._options.heroImageAspectRatio}
          heroImageUrl={this.heroImageUrl()}
          onClickCard={this.onClickPopoverCard}
          title={this.title()}
          getIsTweakModeEnabled={this.getIsTweakModeEnabled}
          videoBackgroundHashedId={this._options.videoBackgroundHashedId}
          videoCount={this.getAllSeriesVideoCount()}
        />,
        this._galleryCardContainer,
      );

      this.resolveEmbeddedOnFirstRender();
    }

    getAllSeriesVideoCount() {
      return this._galleryData.series
        .map((aSeries) => aSeries.sections)
        .reduce((allSections, currentSection) => allSections.concat(currentSection))
        .map((section) => section.videoCount)
        .reduce((totalVideoCount, sectionVideoCount) => totalVideoCount + sectionVideoCount);
    }

    getVideoIds() {
      return this._isFilteringFromSearch ? this.getFilteredVideoIds() : this.getAllVideoIds();
    }

    getAllVideoIds() {
      return this.getAllMedias().map((media) => media.hashedId);
    }

    getFilteredVideoIds() {
      return this.getFilteredMedias().map(({ hashedId }) => hashedId);
    }

    getAllMedias() {
      if (this._cachedAllMedias) {
        return this._cachedAllMedias;
      }

      this._cachedAllMedias = this._galleryData.series[0].sections
        .map((section) => section.videos)
        .reduce((allVideos, sectionVideos) => allVideos.concat(sectionVideos));

      return this._cachedAllMedias;
    }

    getFilteredMedias() {
      return this.getAllMedias().filter(({ hashedId }) =>
        this._searchResultMediaIds.includes(hashedId),
      );
    }

    currentVideo() {
      return this._currentVideo;
    }

    videoContainerId() {
      return `${this._uuid}_video`;
    }

    loadEv1OnPageLoad() {
      return afterPageLoaded().then(loadEv1);
    }

    getGalleryOverlayRoot() {
      if (this._galleryOverlayRoot) {
        return this._galleryOverlayRoot;
      }
      this._galleryOverlayRoot = document.createElement('div');
      this._galleryOverlayRoot.className = 'w-gallery-overlay-root';
      document.body.appendChild(this._galleryOverlayRoot);
      return this._galleryOverlayRoot;
    }

    renderOverlay() {
      render(
        <GalleryOverlay
          contentRef={(e) => (this._overlayContentEl = e)}
          foregroundColor={this._options.foregroundColor}
          galleryOverlayRef={(elem) => (this._overlayEl = elem)}
          isVisible={this._isOverlayVisible}
          onClickClose={this.onClickCloseOverlay}
          videoIsOpen={this._hasHardWall ? false : this._currentVideoId != null}
          zIndex={this._options.overlayZIndex}
        />,
        this.getGalleryOverlayRoot(),
      );
      this._galleryViewContainer = this._overlayContentEl;

      if (this._hasHardWall) {
        return;
      }

      this.renderGalleryView();
    }

    setupInitialGalleryViewEvents() {
      if (this._setupInitialGalleryViewEvents) {
        return;
      }
      this._setupInitialGalleryViewEvents = true;

      const unbindInitialPostersLoaded = this.on('initialpostersloaded', () => {
        unbindInitialPostersLoaded();
        this.maybeFireInitialPaintComplete();

        afterPageLoaded(50).then(() => {
          this._pageLoadComplete = true;
          this.trigger('pageloadcomplete');
          this.updateRenderedViews();

          pageLoadedAndIdle(5000).then(() => {
            this._hasBeenIdleAfterLoad = true;
            this.trigger('idleafterload');
            this.updateRenderedViews();

            setTimeout(() => {
              this.trigger('wayafterload');
            }, 3000);
          });
        });
      });

      this.embedded().then(() => {
        this.maybeFireInitialPostersLoaded();
        this.maybeFireInitialPaintComplete();
      });

      setTimeout(() => {
        // if it's taking over 10 seconds, everything is already crazy slow or
        // there's something broken, so let's let everything else work.
        unbindInitialPostersLoaded();
        this._initialPostersLoaded = true;
        this.maybeFireInitialPaintComplete();
      }, 10000);
    }

    renderGalleryView() {
      if (!this._galleryData) {
        return;
      }

      if (
        this._options.forceDisplayPasswordHardWall === true ||
        this._options.forceDisplayLockedHardWall === true
      ) {
        return;
      }

      const featuredVideoData = this.findVideoData(this._options.featured?.mediaId);

      this.setupInitialGalleryViewEvents();
      this.loadEv1OnPageLoad();
      const prevGalleryViewProps = this._galleryViewProps;
      this._galleryViewProps = {
        allVideoIds: this.getAllVideoIds(),
        askName: this._options.subscribe?.askName,
        bottomText: this._options.subscribe?.bottomText,
        topText: this._options.subscribe?.topText,
        backgroundColor: this._options.backgroundColor,
        color: this._options.color,
        contentOffsetTop: this._options.contentOffsetTop,
        contentTypeLabel: this._options.contentTypeLabel,
        currentVideoId: this._currentVideoId,
        description: this.description(),
        disableStartWatching: Boolean(
          this._options.noHeroCta || this._options.trailerId || !this.firstVideo(),
        ),
        elemRef: (elem) => (this._galleryViewEl = elem),
        featuredDescription: this._options.featured?.description,
        featuredMediaEpisodeId: featuredVideoData?.episodeNumericId,
        featuredMediaId: this._options.featured?.mediaId,
        featuredSectionHeadline: this._options.featured?.sectionHeadline,
        featuredSectionTitle: this._options.featured?.sectionTitle,
        foregroundColor: this._options.foregroundColor,
        galleryContext: this.getGalleryContext(),
        galleryData: this._galleryData,
        galleryEmbedOptions: this._options,
        getVideoEmbedOptions: this.getVideoEmbedOptions,
        hasBeenIdleAfterLoad: this._hasBeenIdleAfterLoad,
        headerFontFamily: this.getHeaderFontFamily(),
        headerFontSizeMultiplier: this._options.headerFontSizeMultiplier,
        heroImageAspectRatio: this._options.heroImageAspectRatio,
        heroImageUrl: this.heroImageUrl(),
        inDarkMode: this._options.backgroundColor === DARK_EMBED_OPTIONS.backgroundColor,
        initialPaintComplete: this._initialPaintComplete,
        initialPostersLoaded: this._initialPostersLoaded,
        isFilteringFromSearch: this._isFilteringFromSearch,
        isVideoOpen: Boolean(Wistia.PopoverV3 && Object(Wistia.PopoverV3._activePopover)._isOpen),
        logoEnabled: this.getLogoEnabled(),
        logoSizeMultiplier: this._options.logoSizeMultiplier,
        logoUrl: this._options.logoUrl,
        navigation: this._options.navigation,
        off: this.offBinded,
        on: this.onBinded,
        onClickOpenSubscribe: this.onClickOpenSubscribe,
        onClickCloseSubscribe: this.onClickCloseSubscribe,
        onClickEpisodeMoreDetails: this.onClickEpisodeMoreDetails,
        onClickEpisodePlay: this.onClickEpisodePlay,
        onClickResume: this.onClickResume,
        onClickSubscribe: this.onClickSubscribe,
        onClickStartWatching: this.onClickStartWatching,
        onClickWatchTrailer: this.onClickWatchTrailer,
        onClickVideoCard: this.onClickVideoCard,
        onUpdateMediaFilterFromSearch: this.onUpdateMediaFilterFromSearch,
        pageFixedHeaderHeight: this._options.pageFixedHeaderHeight,
        pageLoadComplete: this._pageLoadComplete,
        resumableMediaTitle: this.resumableMediaTitle(),
        routeStrategyOptions: this.getRouteStrategyOptions(),
        searchResultMediaIds: this._searchResultMediaIds,
        setBackgroundPosterRef: this.setBackgroundPosterRef,
        setFeaturedMediaRef: this.setFeaturedMediaRef,
        shouldShowResume: this.shouldShowResume(),
        shouldShowTranscript: this._options.shouldShowTranscript,
        shouldShowVideoDescriptions: this._options.shouldShowVideoDescriptions,
        shouldShowHeader: this._options.header?.on !== false,
        shouldShowFeatured: this._options.featured?.on !== false,
        shouldShowSearch: this._options.shouldShowSearch,
        subscribeEnabled: this.subscribeEnabled(),
        subscribeFormIsVisible: this.getSubscribeFormIsVisible(),
        subscribeIsRequired: this.getSubscribeIsRequired(),
        title: this.title(),
        triggerBeforeSubscribe: this.triggerBeforeSubscribe,
        heroAlignment: this._options.heroAlignment || 'left',
        trailerId: this._options.trailerId,
        getIsTweakModeEnabled: this.getIsTweakModeEnabled,
        videoBackgroundHashedId: this._options.videoBackgroundHashedId,
        videoCardsLayout: this._options.videoCardsLayout,
        videoContainerId: this.videoContainerId(),
        viewerIsSubscribed: this.viewerIsSubscribed(),
        visibleSectionId: this._visibleSectionId,
      };

      const needsToRender =
        !this._hasEmbedded ||
        anyValuesChanged(prevGalleryViewProps, this._galleryViewProps, {
          cacheKeys: 'renderGalleryView',
        });

      if (!needsToRender) {
        return;
      }

      render(
        <GalleryViewErrorBoundary
          countMetric={(key, more = {}) => this.countMetric.call(this, key, more)}
        >
          <GalleryView {...this._galleryViewProps} />
        </GalleryViewErrorBoundary>,
        this._galleryViewContainer,
      );

      this.resolveEmbeddedOnFirstRender();
    }

    handleSubmitPassword = (password) => {
      if (this._options.forceDisplayPasswordHardWall === true) {
        return this.handleFakeSubmitPassword(password);
      }

      return new Promise((resolve, reject) => {
        fetchGallerySeedDataFromProject(this.hashedId(), {
          ...this.seedDataOptions,
          password,
        })
          .then((galleryData) => {
            if (galleryData.unauthorized) {
              reject(new InvalidPasswordError('incorrect password'));
              return;
            }

            if (galleryData.embedOptions?.plugin?.passwordProtectedChannel) {
              galleryData.embedOptions.plugin.passwordProtectedChannel.password = password;
            }
            this.initWithData(galleryData, this.seedDataOptions);
          })
          .catch((reason) => {
            if (reason instanceof FetchChannelDataTimeoutError) {
              return reportError(
                'channel',
                new Error('Timed out fetching password-protected channel data'),
                {
                  channelHashedId: this.hashedId(),
                },
              );
            }
            if (reason instanceof Error) {
              return reportError('channel', reason, {
                channelHashedId: this.hashedId(),
              });
            }

            return reportError('channel', new Error('Failed to unlock channel password'), {
              channelHashedId: this.hashedId(),
            });
          });
      });
    };

    handleFakeSubmitPassword = (password) => {
      return new Promise((resolve, reject) => {
        if (this._options.tempPassword === password) {
          this.updateEmbedOptions({ forceDisplayPasswordHardWall: false });

          if (!this._hasHardWall) {
            this.renderGalleryView();
          }
        } else {
          reject(new InvalidPasswordError('incorrect password'));
        }
      });
    };

    renderOverlayWithHardWall = () => {
      this.showOverlay();
      this.container.style.setProperty('display', 'flex');
      this.container.style.setProperty('flex-direction', 'column');

      if (this._needsPassword) {
        render(
          <HardWallWithPasswordForm
            backgroundColor={this._options.backgroundColor}
            logoUrl={this._options.logoUrl}
            playerColor={this._options.color}
            onSubmitPassword={this.handleSubmitPassword}
            channelTitle={this._options.title}
          />,
          this._overlayContentEl,
        );
      } else if (this._noAccess) {
        render(
          <HardWall
            backgroundColor={this._options.backgroundColor}
            logoUrl={this._options.logoUrl}
            playerColor={this._options.color}
            channelTitle={this._options.title}
            inlineMessage="You do not have access to this channel."
          />,
          this.container,
        );
      } else if (this._isLocked) {
        const loginUrl = new URL(
          `https://${appHostname(
            this._options.accountKey,
          )}/channels/${this.hashedId()}/login_redirect${window.location.search}`,
        );
        loginUrl.searchParams.set('show_hard_wall', 'true');
        render(
          <HardWallWithLoginPrompt
            backgroundColor={this._options.backgroundColor}
            logoUrl={this._options.logoUrl}
            playerColor={this._options.color}
            loginUrl={loginUrl}
          />,
          this._overlayContentEl,
        );
      }
    };

    renderHardWall() {
      // If we need to show the hard wall, but the channel is embedded as a popover,
      // we need to first render the popover card and then render the hard wall within an overlay.
      if (this._options.mode === 'popover') {
        render(
          <GalleryPopoverCard
            color={this._options.color}
            elemRef={(elem) => (this._cardEl = elem)}
            fadeInTime={2000}
            galleryEmbedOptions={this._options}
            headerFontFamily={this.getHeaderFontFamily()}
            headerFontSizeMultiplier={this._options.headerFontSizeMultiplier}
            heroImageAspectRatio={this._options.heroImageAspectRatio}
            heroImageUrl={this.heroImageUrl()}
            onClickCard={this.renderOverlayWithHardWall}
            title={this.title()}
            getIsTweakModeEnabled={this.getIsTweakModeEnabled}
            videoBackgroundHashedId={this._options.videoBackgroundHashedId}
            videoCount={0}
          />,
          this.container,
        );
        return;
        // eslint-disable-next-line no-else-return
      } else {
        this.container.style.setProperty('display', 'flex');
        this.container.style.setProperty('flex-direction', 'column');
        if (this._needsPassword || this._options.forceDisplayPasswordHardWall) {
          render(
            <HardWallWithPasswordForm
              backgroundColor={this._options.backgroundColor}
              logoUrl={this._options.logoUrl}
              playerColor={this._options.color}
              onSubmitPassword={this.handleSubmitPassword}
              channelTitle={this._options.title}
            />,
            this.container,
          );
        } else if (this._noAccess) {
          render(
            <HardWall
              backgroundColor={this._options.backgroundColor}
              logoUrl={this._options.logoUrl}
              playerColor={this._options.color}
              channelTitle={this._options.title}
              inlineMessage="You do not have access to this channel."
            />,
            this.container,
          );
        } else if (this._isLocked || this._options.forceDisplayLockedHardWall) {
          const loginUrl = new URL(
            `https://${appHostname(
              this._options.accountKey,
            )}/channels/${this.hashedId()}/login_redirect${window.location.search}`,
          );
          loginUrl.searchParams.set('show_hard_wall', 'true');
          render(
            <HardWallWithLoginPrompt
              backgroundColor={this._options.backgroundColor}
              logoUrl={this._options.logoUrl}
              playerColor={this._options.color}
              loginUrl={loginUrl}
            />,
            this.container,
          );
        }
      }
      this.resolveEmbeddedOnFirstRender();
    }

    monitor() {
      globalEventLoop.add(`${this._uuid}.monitor`, 500, () => {
        this.ensureInDom();
        if (this._galleryViewEl) {
          this.renderGalleryViewIfDimensionsChanged();
        }

        if (this._cardEl) {
          this.renderGalleryCardIfDimensionsChanged();
        }
      });
    }

    ensureInDom() {
      if (this.container && !elemInDom(this.container)) {
        this.destroy();
        return;
      }

      if (
        this._galleryViewEl &&
        !elemInDom(this._galleryViewEl) &&
        elemInDom(this._galleryViewContainer)
      ) {
        this._galleryViewContainer.appendChild(this._galleryViewEl);
      }

      if (this._cardEl && !elemInDom(this._cardEl) && elemInDom(this._galleryCardContainer)) {
        this._galleryCardContainer.appendChild(this._cardEl);
      }
    }

    optsForOpeningEpisode = (elem, moreOpts) => {
      // We should check !isMouseDownRecently here so that we do not run into
      // timing issues due to async file loading in popovers, for example.
      return {
        initiatingElem: elem,
        showFocusOutline: !isMouseDownRecently(),
        shouldPlay: true,
        ...moreOpts,
      };
    };

    isEmailCollectorOn() {
      return this.pluginOptions('emailCollector').on;
    }

    renderGalleryCardIfDimensionsChanged() {
      this.galleryCardHeightNow = elemHeight(this._galleryCardContainer);
      this.galleryCardWidthNow = elemWidth(this._galleryCardContainer);
      if (
        this.previewGalleryCardHeight &&
        this.previewGalleryCardWidth &&
        (this.galleryCardHeightNow !== this.previewGalleryCardHeight ||
          this.galleryCardWidthNow !== this.previewGalleryCardWidth)
      ) {
        this.renderGalleryCard();
      }
      this.previewGalleryCardHeight = this.galleryCardHeightNow;
      this.previewGalleryCardWidth = this.galleryCardWidthNow;
    }

    renderGalleryViewIfDimensionsChanged() {
      this.galleryViewHeightNow = elemHeight(this._galleryViewContainer);
      this.galleryViewWidthNow = elemWidth(this._galleryViewContainer);
      if (
        this.previewGalleryViewHeight &&
        this.previewGalleryViewWidth &&
        (this.galleryViewHeightNow !== this.previewGalleryViewHeight ||
          this.galleryViewWidthNow !== this.previewGalleryViewWidth)
      ) {
        this.renderGalleryView();
      }
      this.previewGalleryViewHeight = this.galleryViewHeightNow;
      this.previewGalleryViewWidth = this.galleryViewWidthNow;
    }

    firstVideo() {
      let video;
      this._galleryData.series[0].sections.some((section) => {
        return (video = section.videos[0]);
      });
      return video;
    }

    getIdOfLastMediaInChannel() {
      const allVideoIds = this.getAllVideoIds();
      return allVideoIds[allVideoIds.length - 1];
    }

    onClickPopoverCard = () => {
      this.countMetric('clicked-popover-card');
      this.openGalleryAndUpdateUrl();
    };

    onClickCloseOverlay = () => {
      this.countMetric('clicked-close-overlay');
      this.exitGalleryAndUpdateUrl();
    };

    onClickCloseSubscribe = () => {
      this.setSubscribeFormIsVisible(false);
    };

    restoreClosedVideoAndTime() {
      if (this._videoAtClose) {
        const videoId = this._videoAtClose;
        this._hashedIdOfMediaToPlayAfterSubscribe = videoId;
        if (this.getSubscribeIsRequired() && !this.viewerIsSubscribed()) {
          this.setSubscribeFormIsVisible(true);
          return;
        }
        this.openAndMaybePlayEpisode(videoId);
        this._videoAtClose = undefined;
        if (this._timeAtClose) {
          // after we open the video seek to this._timeAtClose
          const time = this._timeAtClose;
          this._timeAtClose = undefined;
          setTimeout(() => {
            Wistia.api(videoId).time(time);
          }, 500);
        }
      }
    }

    onClickOpenSubscribe = () => {
      this.countMetric('clicked-open-subscribe');
      // null this out because we only want to open the last clicked video card
      // upon submitting the subscribe form if the form was opened via clicking
      // a video card.
      this._hashedIdOfMediaToPlayAfterSubscribe = null;
      this.setSubscribeFormIsVisible(true);

      // Google Tag Manager wadmin-gated beta flag allows setting event triggers in editor panel.
      // Only used internally for Brandwagon, etc.
      const subscribeModalOpenEventTrigger = this._options.subscribeModalOpenEventTrigger;

      if (subscribeModalOpenEventTrigger && 'dataLayer' in window) {
        window.dataLayer.push({
          event: 'wistia.subscribeModal.opened',
          eventId: subscribeModalOpenEventTrigger,
        });
      }
    };

    onClickSubscribe = (event, { firstName, lastName, email, foreignData, visitorKey }) => {
      event.preventDefault();
      const url = `https://${appHostname('app')}/subscribe`;
      const params = {
        channel_id: this.hashedId(),
        email_address: email,
        first_name: firstName,
        last_name: lastName,
        foreign_data: foreignData,
        list_id: this.getListId(),
        visitor_key: visitorKey,
      };

      // For customers who want to setup their own integration handling.
      this.trigger('subscribe', { firstName, lastName, email, foreignData, visitorKey });

      return fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }).then((response) => {
        const subscribeEventTrigger = this._options.subscribeEventTrigger;

        // Update cookies when user subscribes so we no longer show subscribe button
        if (response.status === 200) {
          updateChannelStorage(this.hashedId(), (ls) => {
            ls.subscribeInfo = {
              at: Date.now(),
            };
          });

          // Google Tag Manager wadmin-gated beta flag allows setting event triggers in editor panel.
          // Only used internally for Brandwagon, etc.
          if (subscribeEventTrigger && 'dataLayer' in window) {
            window.dataLayer.push({
              event: 'wistia.subscribe.success',
              eventId: subscribeEventTrigger,
            });
          }

          this.enableOpeningPopovers();

          // to make the lock icons go away
          this.renderGalleryView();

          if (this._hashedIdOfMediaToPlayAfterSubscribe) {
            this.openAndMaybePlayEpisode(this._hashedIdOfMediaToPlayAfterSubscribe);
          }
        }
      });
    };

    onClickResume = (elem) => {
      this.countMetric('clicked-resume');
      const idOfMediaToResume = this.idOfMediaToResume();
      this._hashedIdOfMediaToPlayAfterSubscribe = idOfMediaToResume;
      if (this.getSubscribeIsRequired() && !this.viewerIsSubscribed()) {
        this.setSubscribeFormIsVisible(true);
        return;
      }
      this.openAndMaybePlayEpisode(idOfMediaToResume, this.optsForOpeningEpisode(elem));
    };

    nextMediaId() {
      const allVideoIds = this.getAllVideoIds();
      const mostRecentlyPlayedMediaId = this.mostRecentlyPlayedMediaId();
      return allVideoIds[allVideoIds.indexOf(mostRecentlyPlayedMediaId) + 1];
    }

    onClickStartWatching = (elem) => {
      const firstVideoId = this.firstVideo().hashedId;
      // make it not resume when played via Start Watching. Otherwise, if a
      // viewer reaches the end of the channel and then clicks Start Watching
      // again, they would end up wherever they'd left off in the first media.
      setLastTime(firstVideoId, 0);
      this.countMetric('clicked-start-watching');
      this._hashedIdOfMediaToPlayAfterSubscribe = firstVideoId;
      if (this.getSubscribeIsRequired() && !this.viewerIsSubscribed()) {
        this.setSubscribeFormIsVisible(true);
        return;
      }
      this.openAndMaybePlayEpisode(firstVideoId, this.optsForOpeningEpisode(elem));
    };

    onClickWatchTrailer = () => {
      this.countMetric('clicked-watch-trailer');
      this._isWatchingTrailer = true;
    };

    onClickVideoCard = (hashedId, elem) => {
      this.countMetric('clicked-video-card');
      this._hashedIdOfMediaToPlayAfterSubscribe = hashedId;
      if (this.getSubscribeIsRequired() && !this.viewerIsSubscribed()) {
        this.setSubscribeFormIsVisible(true);
        return;
      }
      this.openAndMaybePlayEpisode(hashedId, this.optsForOpeningEpisode(elem));
    };

    onUpdateMediaFilterFromSearch = ({
      isFilteringFromSearch,
      searchResultMediaIds,
      shouldRerender = true,
    }) => {
      this._isFilteringFromSearch = isFilteringFromSearch;
      this._searchResultMediaIds = searchResultMediaIds;
      if (shouldRerender) {
        this.renderGalleryView();
      }
    };

    onClickEpisodePlay = (hashedId, elem) => {
      this._hashedIdOfMediaToPlayAfterSubscribe = hashedId;
      if (this.getSubscribeIsRequired() && !this.viewerIsSubscribed()) {
        this.setSubscribeFormIsVisible(true);
        return;
      }
      this.openAndMaybePlayEpisode(hashedId, this.optsForOpeningEpisode(elem));
    };

    onClickEpisodeMoreDetails = (hashedId, elem) => {
      this._hashedIdOfMediaToPlayAfterSubscribe = hashedId;
      if (this.getSubscribeIsRequired() && !this.viewerIsSubscribed()) {
        this.setSubscribeFormIsVisible(true);
        return;
      }
      this.openAndMaybePlayEpisode(
        hashedId,
        this.optsForOpeningEpisode(elem, { shouldPlay: false }),
      );
    };

    onLoadingPoster = (hashedId) => {
      this._posterStatus[hashedId] = 'loading';
    };

    onLoadedPoster = (hashedId) => {
      this._posterStatus[hashedId] = 'loaded';
      this.maybeFireInitialPostersLoaded();
    };

    maybeFireInitialPostersLoaded() {
      const allVisiblePostersAreLoaded = Object.keys(this._posterStatus).every((hashedId) => {
        return this._posterStatus[hashedId] === 'loaded';
      });

      if (allVisiblePostersAreLoaded && !this._initialPostersLoaded) {
        this._initialPostersLoaded = true;
        this.trigger('initialpostersloaded');
        this.updateRenderedViews();
      }
    }

    maybeFireInitialPaintComplete() {
      if (this._initialPaintComplete) {
        return;
      }

      if (this._initialPostersLoaded) {
        this._initialPaintComplete = true;
        this.trigger('initialpaintcomplete');
        this.updateRenderedViews();
      }
    }

    pushStateToHistory(routeData) {
      const url = this.getUrlWithRouteData(routeData);

      Wistia.galleryHistory.pushState(routeData, url);
    }

    pluginOptions(pluginName) {
      return Object(this._options.plugin)[pluginName] || {};
    }

    onVideoHasData = (video) => {
      if (video.iframe) {
        // Channels should never operate on iframe embeds in this manner.
        return;
      }

      if (video._opts.channel !== this.hashedId()) {
        return;
      }

      this._durationOfMostRecentlyPlayedMedia = video.duration();

      const poster = Wistia.poster(video.sourceHashedId());
      if (poster?.controls?.progressIndicator) {
        video.on('secondchange', () => {
          poster.controls.progressIndicator.renderProgressIndicator();
        });
      }

      if (this.isEmailCollectorOn()) {
        video.on('conversion', (type, email, firstName, lastName) => {
          this._cachedVideoEmbedOptions = undefined;
          updateChannelStorage(this.hashedId(), (ls) => {
            ls.conversionData = {
              at: Date.now(),
              email,
              firstName,
              lastName,
              type,
              video: this._currentVideoId,
            };
          });
          // We set the video's playlist upon converion? Weird, huh? Here's why:
          // If the customer has enabled Turnstile on all videos in the channel,
          // we want the Turnstiles on all other videos to be disabled after a
          // viewer fills out a single one. So we must set the embed options
          // for the whole playlist when a conversion event happens anywhere.
          video.setPlaylist(this.getAllVideoIds(), this.getVideoEmbedOptions());
        });
      }
    };

    doesMediaBelongToChannel(video) {
      return video._gatherOptions().channel === this.hashedId();
    }

    setVideoInGalleryViewAndUpdateUrl(hashedId) {
      const { wchannelid } = this.getRouteData();
      const routeData = {
        wchannelid,
        wmediaid: hashedId,
      };
      this.pushStateToHistory(routeData);
      maybeResetOriginalUrlForFreshUrl();
      this.updateView({ videoId: hashedId });
    }

    onVideoInit = (video) => {
      if (video.iframe) {
        // Channels should never operate on iframe embeds in this manner.
        return;
      }

      if (!this.doesMediaBelongToChannel(video)) {
        return;
      }

      video.hasData(() => {
        video.bind('play', () => {
          this.updateStorage((ls) => {
            ls.lastVideoPlayed = video.sourceHashedId();
          });

          return video.unbind;
        });

        video.bind('afterreplace', () => {
          if (this._currentVideoId !== video.hashedId()) {
            this.setVideoInGalleryViewAndUpdateUrl(video.sourceHashedId());
            video._attrs.pageUrl = video._inferPageUrl();
          }
          return video.unbind;
        });

        this._currentVideo = video;

        const trailerId = this._options.trailerId;
        const allVideoIds = this.getAllVideoIds();
        const theVideoWeAreMountingIsTheTrailer = video.sourceHashedId() === trailerId;

        let videoIdsForPlaylist;

        // if _watchingTrailer is true, and the video we're mounting is the trailer, we know the user clicked the play trailer button, so we need to prepend the trailer to the playlist so all of our episodes autoplay afterwards
        // and then we can set _watchingTrailer to false so that the next time a video is mounted we don't do this again
        if (theVideoWeAreMountingIsTheTrailer && this._isWatchingTrailer) {
          videoIdsForPlaylist = [trailerId].concat(allVideoIds);
          this._isWatchingTrailer = false;
        }
        // otherwise the user asked to play the episode that is also the trailer, or some other video, so we don't need to do anything special. If they previously played the trailer, this sets
        // the playlist back up without the trailer prepended
        else {
          videoIdsForPlaylist = allVideoIds;
        }

        video.setPlaylist(videoIdsForPlaylist, this.getVideoEmbedOptions());

        this.trigger('currentvideochange', video.sourceHashedId());
      });
    };

    onVideoReady = (video) => {
      video._impl.whenVideoElementInDom().then(() => {
        // Retrieve playbackRate from channel preferences and apply it once the engine is available
        const channelPreferences = getChannelStorage(this.hashedId());
        if (
          channelPreferences.playbackRate &&
          channelPreferences.playbackRate !== video._impl.engine.getPlaybackRate()
        ) {
          video._impl.engine.setPlaybackRate(channelPreferences.playbackRate);
        }

        // Retrieve captions settings from channel preferences and apply them once the captions plugin is available
        video.plugin('captions').then((captionsPlugin) => {
          const channelPreferences = getChannelStorage(this.hashedId());
          // It shouldn't be possible to get into a state where `captionsEnabled` exists
          // in channel preferences and `captionsLanguage` does not, but as an extra precaution
          // don't try to enable captions unless the language is also defined.
          if (
            channelPreferences.captionsEnabled &&
            channelPreferences.captionsLanguage &&
            channelPreferences.captionsLanguage !== '_off_' &&
            channelPreferences.captionsEnabled !== video.controls.captionsEnabled
          ) {
            // Make sure to wait for the captions data to load before trying to enable them
            captionsPlugin.allMountedAndFetched().then(() => {
              captionsPlugin.setLanguage(channelPreferences.captionsLanguage);
              captionsPlugin.show();
            });
          }
        });
      });

      video.bind('mutechange', (isMuted) => {
        this.updateStorage((ls) => {
          ls.muted = isMuted;
        });
        this.updateEmbedOptions({ channelPreferences: getChannelStorage(this.hashedId()) });
      });

      video.bind('volumechange', (v) => {
        this.updateStorage((ls) => {
          ls.volume = v;
        });
        this.updateEmbedOptions({
          volume: v,
          channelPreferences: getChannelStorage(this.hashedId()),
        });
        // The 'volumechange' event also dispatches 'mutechange', so we can consolidate this logic here
        // and avoid iterating through all the channel episodes multiple times.
        this.channelPopovers().forEach((popover) => {
          popover.clearAllCachedVolumeOptions();
        });
      });

      video.bind('playbackratechange', (playbackRate) => {
        this.updateStorage((ls) => {
          ls.playbackRate = playbackRate;
        });
        this.updateEmbedOptions({ channelPreferences: getChannelStorage(this.hashedId()) });
        // Since 'playbackrate' isn't an embed option, we don't need to clear all the cached options for each popover
      });

      video.bind('captionschange', (details) => {
        this.updateStorage((ls) => {
          ls.captionsEnabled = details.visible;
          ls.captionsLanguage = details.language;
        });
        this.updateEmbedOptions({ channelPreferences: getChannelStorage(this.hashedId()) });
        // Since captions aren't an embed option, we don't need to clear all the cached options for each popover
      });
    };

    setView(view) {
      const { sectionId, shouldShowOverlay, subscribeFormIsVisible, videoId } = view;

      this._view = {
        sectionId,
        shouldShowOverlay,
        subscribeFormIsVisible,
        videoId,
      };

      if (shouldShowOverlay) {
        this.showOverlay();
      } else {
        const wasOverlayVisible = this._isOverlayVisible;
        this.hideOverlay();
        if (wasOverlayVisible) {
          this.updateSeoTags();
        }
      }

      // If there is a hard wall, we don't have access to any episode/media data,
      // so we shouldn't try to render anything beyond this point.
      if (this._hasHardWall) {
        return;
      }

      // For the gallery to be visible, we must either be in an open overlay, or
      // be in an inline gallery. Only bother setting the view if we're in one
      // of those two situations.
      if (shouldShowOverlay || this._options.mode === 'inline') {
        this._visibleSectionId = sectionId;

        this._currentVideoId = videoId;

        if (!videoId) {
          this._currentVideo = undefined;
          this.trigger('currentvideochange', undefined);
        }
        this.updateSeoTags();
        this.fetchDurationOfMostRecentlyPlayedMedia().then(() => {
          this.maybeUpdateResumableMediaTitle();
        });
        this.renderGalleryView();

        // Unset _visibleSectionId so subsequent calls to renderGalleryView()
        // don't cause undesired scrolling.
        this._visibleSectionId = undefined;
      }
    }

    idOfMediaToResume() {
      const mostRecentlyPlayedMediaId = this.mostRecentlyPlayedMediaId();
      if (atOrNearEnd(mostRecentlyPlayedMediaId, this._durationOfMostRecentlyPlayedMedia)) {
        return this.nextMediaId();
      }

      return mostRecentlyPlayedMediaId;
    }

    maybeUpdateResumableMediaTitle() {
      const idOfMediaToResume = this.idOfMediaToResume();

      if (!idOfMediaToResume) {
        return;
      }

      batchFetchData(idOfMediaToResume, this._options, { basic: true }).then(
        ({ basic: { name } }) => {
          this._resumableMediaTitle = name;
          this.renderGalleryView();
        },
      );
    }

    updateSeoTags() {
      this.setCanonicalTag(this._currentVideoId);
      this.setDocumentTitle();
      this.setMetaDescription();
      this.setOgImage();
      this.updateInjectedJsonLd(this._currentVideoId, this.hashedId());
    }

    updateView(options) {
      const view = clone(this._view);
      Object.keys(options).forEach((key) => {
        view[key] = options[key];
      });
      return this.setView(view);
    }

    getRouteStrategyOptions() {
      if (this._cachedRouteStrategyOptions) {
        return this._cachedRouteStrategyOptions;
      }
      this._cachedRouteStrategyOptions = {
        routeDataToUrl: this._options.routeDataToUrl,
        routeStrategy: this.routeStrategy,
        urlToRouteData: this._options.urlToRouteData,
      };
      return this._cachedRouteStrategyOptions;
    }

    subscribeEnabled() {
      const { on = false, accountAllowsNewSubscribers = false } = this._options.subscribe || {};

      return on && accountAllowsNewSubscribers;
    }

    getSubscribeIsRequired() {
      const { required } = this._options.subscribe || {};
      return this.subscribeEnabled() && required;
    }

    getAskName() {
      const subscribe = this._options.subscribe;
      const askName =
        subscribe && Object.hasOwn(subscribe, 'askName') && subscribe.askName === true;

      return Boolean(askName) || false;
    }

    getBottomText() {
      const subscribe = this._options.subscribe;

      return subscribe && subscribe.bottomText;
    }

    getTopText() {
      const subscribe = this._options.subscribe;

      return subscribe && subscribe.topText;
    }

    getListId() {
      return this._options.subscribe?.list;
    }

    setSubscribe(subscribe) {
      this.updateEmbedOptions({ subscribe });
    }

    getUrlWithRouteData(routeData, url = location.href) {
      // For an added layer of security, we want to avoid surfacing the media id's of media
      // within a password-protected channel in the URL as much as possible.
      // If a media id is present in the routeData, we should replace it with its episode id instead.
      if (routeData.wmediaid && this.isPasswordProtected()) {
        const episodeId = this.getVideoEmbedOptions().episodeIdMappings[routeData.wmediaid];

        delete routeData.wmediaid;
        routeData.wepisodeid = episodeId;
      }

      return setRouteDataOnUri(url, routeData, this.getRouteStrategyOptions());
    }

    setCanonicalTag(videoId) {
      const routeData = {
        wchannelid: this.hashedId(),
      };
      // If there's no videoId, it's not sufficient to have the wmediaid
      // property be undefined in routeData. Instead, routeData should not
      // have a wmediaid property at all.
      if (videoId) {
        routeData.wmediaid = videoId;
      }

      // If we're closing a video popover and we're not in popover mode, make
      // sure we don't include wchannelid in the canonical.
      if (this._options.mode !== 'popover' && routeData.wchannelid && !routeData.wmediaid) {
        delete routeData.wchannelid;
      }

      // If the popover is closed, make sure we don't include our params in
      // the canonical.
      if (this._options.mode === 'popover' && !this._isOverlayVisible) {
        delete routeData.wchannelid;
        delete routeData.wmediaid;
      }

      const newUrl = this.getUrlWithRouteData(routeData, originalCanonicalUrl());

      upsertMetaTag('og:url', newUrl);
      upsertMetaTag('twitter:site', newUrl);

      const canonicalTag = document.querySelector('link[rel=canonical]');
      if (canonicalTag) {
        canonicalTag.setAttribute('href', newUrl);
      } else {
        const newTag = document.createElement('link');
        newTag.setAttribute('rel', 'canonical');
        newTag.setAttribute('href', newUrl);
        document.head.appendChild(newTag);
      }
    }

    findVideoData(hashedId) {
      if (!hashedId) {
        return undefined;
      }

      const sections = this._galleryData.series[0].sections;
      for (let i = 0; i < sections.length; i++) {
        for (let j = 0; j < sections[i].videos.length; j++) {
          const videoData = sections[i].videos[j];
          if (videoData.hashedId === hashedId) {
            return videoData;
          }
        }
      }
    }

    setOgImage() {
      if (this._isGhostChannel) {
        return;
      }

      if (!this._currentVideoId) {
        upsertMetaTag('og:image', this._originalOgImage);
        upsertMetaTag('og:image:width', this._originalOgImageWidth);
        upsertMetaTag('og:image:height', this._originalOgImageHeight);
        upsertMetaTag('twitter:image', this._originalTwitterImage);
        return;
      }

      fetchMediaData(this._currentVideoId, this._options).then((mediaData) => {
        const stillImage = still(mediaData.assets);

        try {
          const stillUrl = new Url(stillImage.url);
          stillUrl.ext('jpg');
          upsertMetaTag('og:image', stillUrl.absolute());
          upsertMetaTag('og:image:width', stillImage.width);
          upsertMetaTag('og:image:height', stillImage.height);
          upsertMetaTag('twitter:image', stillUrl.absolute());
        } catch (error) {
          // TODO swallow error for now to clean up console logging
          // should be correctly handled later when audio is further along
          // throw new Error(error);
        }
      });
    }

    setMetaDescription() {
      if (!this._currentVideoId) {
        upsertMetaTag('description', this._originalMetaDescription);
        upsertMetaTag('twitter:description', this._originalTwitterDescription);
        upsertMetaTag('og:description', this._originalTwitterDescription);
        return;
      }

      batchFetchData(this._currentVideoId, this._options, { basic: true }).then(
        ({ basic: { description } }) => {
          const shortDescription = getShortDescription(description);
          upsertMetaTag('description', shortDescription);
          upsertMetaTag('twitter:description', shortDescription);
        },
      );
    }

    setDocumentTitle() {
      const originalTitle = this._originalTitle;
      const overlayIsVisible = this._isOverlayVisible;
      const videoData = this.findVideoData(this._currentVideoId);

      if (!videoData) {
        document.title = unescapeHtml(this._originalTitle);
        upsertMetaTag('og:title', this._originalTitle);
        upsertMetaTag('twitter:title', this._originalTwitterTitle);
        return;
      }

      const segments = [];
      const seriesTitle = this._options.title || this._galleryData.series[0].title;
      const videoTitle = this._currentVideoId && videoData.name;

      if (videoTitle) {
        segments.push(videoTitle);
      }

      if (seriesTitle && (overlayIsVisible || videoTitle)) {
        segments.push(seriesTitle);
      }

      const title = unescapeHtml(segments.join(' - ') || originalTitle);
      document.title = title;
      upsertMetaTag('og:title', title);
      upsertMetaTag('twitter:title', title);
    }

    showOverlay = () => {
      if (this._isOverlayVisible) {
        return;
      }
      this._isOverlayVisible = true;
      this.renderOverlay();
      this._unbindEscHandler = elemBind(document, 'keyup', (e) => {
        if (e.keyCode === 27 && !this._isPopoverOpen && !e.escapeHandled) {
          this.exitGalleryAndUpdateUrl();
        }
      });
    };

    hideOverlay = () => {
      if (!this._isOverlayVisible) {
        return;
      }

      this._isOverlayVisible = false;
      this.renderOverlay();
      this._unbindEscHandler();
      this._unbindEscHandler = null;

      if (this._options.mode === 'inline') {
        this.container.style.height = this._containerHeightStyleBeforeOverlay;
        this._galleryViewContainer = this.container;
        this.renderGalleryView();
      }
    };

    hashedId = () => {
      return this._hashedId;
    };

    lastSectionPositionInSeries() {
      const thisSeries = this._galleryData.series[0];
      const lastSection = thisSeries.sections[thisSeries.sections.length - 1];

      if (lastSection == null) {
        return SMALLEST_MYSQL_INTEGER_VALUE;
      }

      return lastSection.position;
    }

    lastVideoPositionInGroup(mediaGroupId) {
      const thisSeries = this._galleryData.series[0];
      const thisSection = thisSeries.sections.filter((s) => s.numericId === mediaGroupId)[0];
      const lastVideo = thisSection.videos[thisSection.videos.length - 1];

      if (lastVideo === null) {
        return SMALLEST_MYSQL_INTEGER_VALUE;
      }

      return lastVideo.position;
    }

    updateRenderedViews() {
      if (this._cardEl) {
        this.renderGalleryCard();
      }
      if (this._galleryViewEl) {
        this.renderGalleryView();
      }
    }

    setColor(color) {
      this.updateEmbedOptions({ color });
    }

    setBackgroundColor(hex) {
      this.updateEmbedOptions({ backgroundColor: hex });
    }

    getForegroundColor() {
      return this._options.foregroundColor || 'ffffff';
    }

    setForegroundColor(hex) {
      this.updateEmbedOptions({ foregroundColor: hex });
    }

    setHeroImageUrl(url) {
      return waitForImgSrcToLoad(url)
        .then((img) => {
          const aspect = img.naturalWidth / img.naturalHeight;
          this.updateEmbedOptions({ heroImageUrl: url, heroImageAspectRatio: aspect });
        })
        .catch((reason) => reportError('channel', reason));
    }

    heroImageUrl() {
      return this._options.heroImageUrl || this._galleryData.firstVideoStillUrl;
    }

    description() {
      if (this._options.noDescription) {
        return '';
      }

      if (this._options.description) {
        return String(this._options.description || '').replace(/^\s+|\s+$/g, '');
      }

      return String(this._galleryData.series[0].description || '').replace(/^\s+|\s+$/g, '');
    }

    setDescription(description) {
      this.updateEmbedOptions({ description });
    }

    setFeaturedSectionHeadline(sectionHeadline) {
      this.updateEmbedOptions({ featured: { sectionHeadline } });
    }

    setFeaturedDescription(description) {
      this.updateEmbedOptions({ featured: { description } });
    }

    setFeaturedSectionTitle(sectionTitle) {
      this.updateEmbedOptions({ featured: { sectionTitle } });
    }

    setFeaturedMediaId(mediaId) {
      this.updateEmbedOptions({ featured: { mediaId } });
    }

    title() {
      if (this._options.noTitle) {
        return '';
      }

      if (this._options.title) {
        return `${this._options.title}`.replace(/^\s+|\s+$/g, '');
      }

      return `${this._galleryData.series[0].title || ''}`.replace(/^\s+|\s+$/g, '');
    }

    setTitle(title) {
      this.updateEmbedOptions({ title });
    }

    setLogoUrl(logoUrl) {
      this.updateEmbedOptions({ logoUrl });
    }

    setHeroAlignment(heroAlignment) {
      this.updateEmbedOptions({ heroAlignment });
    }

    setVideoBackgroundHashedId(videoBackgroundHashedId) {
      this.updateEmbedOptions({ videoBackgroundHashedId });
    }

    setTrailerId(trailerId) {
      this.updateEmbedOptions({ trailerId });
    }

    getStorage() {
      return getChannelStorage(this.hashedId());
    }

    updateStorage(fn) {
      return updateChannelStorage(this.hashedId(), fn);
    }

    setVideoCardsLayout(layout) {
      this.updateEmbedOptions({ videoCardsLayout: layout });
    }

    setContentOffsetTop(contentOffsetTop) {
      this.updateEmbedOptions({ contentOffsetTop });
    }

    setPageFixedHeaderHeight(pageFixedHeaderHeight) {
      this.updateEmbedOptions({ pageFixedHeaderHeight });
    }

    destroy() {
      // Let any bindings do their own cleanup.
      this.trigger('beforedestroy');

      // Stop monitoring for size changes and hydration weirdness
      globalEventLoop.remove(`${this._uuid}.monitor`);

      Wistia.galleryHistory.unregister(this.hashedId());

      // Clear any timeouts for search
      clearTimeouts('channel_search');

      // Unbind any global bindings, like popstate on window
      this._unbinds.forEach((u) => {
        try {
          u();
        } catch (e) {
          setTimeout(() => {
            throw e;
          }, 0);
        }
      });

      // Remove the API association on the container so it can be garbage
      // collected.
      this.container.wistiaGallery = undefined;

      // Remove wistia_channel from the classList so it doesn't get
      // reinitialized by the DOM watcher.
      this.container.classList.remove('wistia_channel');
      this.container.classList.add('wistia_channel_destroyed');

      // Remove the reference to the overlay, and emtpy its root elem
      if (this._overlayEl) {
        this._overlayEl = undefined;
        this._galleryOverlayRoot.innerHTML = '';
        document.body.removeChild(this._galleryOverlayRoot);
        this._galleryOverlayRoot = undefined;
      }

      // Remove whatever was in the container: popover or inline content.
      this.container.innerHTML = '';

      // Remove the container association so it can be garbage collected
      // cleanly.
      this.container = undefined;

      galleryRegistry.unregister(this._uuid);

      addRevokeToQueue('_wq', this._channelVideoConfigObject);
      addRevokeToQueue('_wpq', this._channelPopoverConfigObject);
      addRevokeToQueue('wistiaPosterApiQueue', this._channelPosterConfigObject);

      // Force a flush of the popover queue now so that we don't have to wait
      // for the next flush to happen. Otherwise, if another channel is registered
      // in the meantime we can double up on bound popover events and cause weirdness
      Wistia._popoverQueueFlusher?.flush();
    }

    resolveEmbeddedOnFirstRender() {
      // we can say that we're embedded as soon as we've done one render
      // and preact is synchronous
      if (this._hasEmbedded === false) {
        this._hasEmbedded = true;
        this.trigger('embedded');
        performance.mark('channel_embed_complete');

        if (detect().performanceMeasure) {
          this.sampleMetric(
            'startup-time',
            performance.measure(
              'channel_embed_duration',
              'channel_embed_start',
              'channel_embed_complete',
            ).duration,
          );
        }
      }
    }

    onBinded = (...args) => this.on(...args);

    offBinded = (...args) => this.off(...args);

    setBackgroundPosterRef = (p) => {
      this._backgroundPoster = p;
    };

    setFeaturedMediaRef = (m) => {
      this._featuredMedia = m;
    };

    viewerIsSubscribed() {
      return Boolean(getChannelStorage(this.hashedId()).subscribeInfo);
    }
  }

  bindify(Gallery.prototype);

  Wistia.Gallery = Gallery;
}

export default Wistia.Gallery;
